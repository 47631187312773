import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App'
import store from './store'
import router from './router'
import '@/custom-component' // 注册自定义组件
import axios from 'axios'

import '@/assets/iconfont/iconfont.css'
import '@/styles/animate.scss'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/reset.css'
import '@/styles/global.scss'
import '@/styles/dark.scss'

Vue.use(ElementUI, { size: 'small' })
Vue.config.productionTip = false
Vue.prototype.$axios = axios // 全局注册，使用方法为:this.$axios
axios.defaults.baseURL = 'https://photo.fotopia.fun/' // 请求的默认域名
new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App),
})
